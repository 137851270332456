import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';
import cn from 'classnames';

import './styles.css';

interface ToolTipProps extends TippyProps {
  label?: string;
  checkWidth?: boolean;
  children: ReactElement;
}

export const ToolTip: FC<ToolTipProps> = ({ label, checkWidth, children, ...props }) => {
  const ref = useRef(null);
  const [hidden, setHidden] = useState(!(!checkWidth && label));

  useEffect(() => {
    if (!ref?.current || !label) {
      return;
    }

    const { offsetWidth, scrollWidth } = ref.current as HTMLDivElement;

    if (offsetWidth < scrollWidth) {
      setHidden(false);
    }
  }, []);

  return (
    <Tippy
      ref={ref}
      animateFill={false}
      content={label}
      className={cn(
        { hidden: hidden },
        'font-default-medium line-clamp-6 rounded bg-blue-800 text-extra-xs-big',
      )}
      {...props}
    >
      {children}
    </Tippy>
  );
};
