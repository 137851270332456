import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container } from 'src/entity/Container';
import { FavoriteButton } from 'src/entity/FavoriteButton';
import { ProductAddButton } from 'src/entity/ProductAddButton';
import BasketService from 'src/shared/api/basket/BasketService';
import { ProductType } from 'src/shared/api/catalog';
import PaymentService from 'src/shared/api/payment/PaymentService';
import { ReactComponent as TrashIcon } from 'src/shared/assets/icons/kit/trash.svg';
import { ReactComponent as TrashXIcon } from 'src/shared/assets/icons/kit/trash-x.svg';
import { ReactComponent as EmptyShoppingCartIcon } from 'src/shared/assets/images/empty-shopping-cart.svg';
import { routesEnum } from 'src/shared/const';
import { addSpaces, declOfNum } from 'src/shared/lib/utils';
import { BasketContext, UserContext } from 'src/shared/store';
import { Button, CheckBox, DiscountPercent, DiscountPrice, WhiteBox } from 'src/shared/ui';
import { Loader } from 'src/shared/ui/Loader';
import cn from 'classnames';

export const BasketPage: FC = () => {
  const { user } = useContext(UserContext);
  const { basket, setIsBasketLoading, setBasket, isBasketLoading } = useContext(BasketContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingChecked, setIsLoadingChecked] = useState(false);

  useEffect(() => {
    if (!user) {
      return;
    }

    (async () => {
      try {
        setIsBasketLoading(true);

        const { data } = await BasketService.getBasket(user.id);
        setBasket(data);
      } catch (e) {
        setBasket([]);
      } finally {
        setIsBasketLoading(false);
      }
    })();
  }, [user]);

  const isAllSelected = useMemo(() => {
    if (!basket.length) {
      return true;
    }

    return !basket.some(el => !el.checked);
  }, [basket]);

  const handleGetToken = async () => {
    if (isLoading || !user) {
      return;
    }

    setIsLoading(true);

    try {
      const { data } = await PaymentService.getConfirmationToken({
        email: user.email,
        userId: user.id,
      });
      navigate(`/payment/${data.confirmation_token}`);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const summ = useMemo(() => {
    const res = { count: 0, price: 0 };

    if (!basket.length) {
      return res;
    }

    basket.forEach(el => {
      if (el.checked) {
        res.price += el.amount * +(el.newPrice.replace(',', '.') || 0);
        res.count += el.amount;
      }
    });

    return { count: +res.count.toFixed(2), price: +res.price.toFixed(2) };
  }, [basket]);

  const selectAll = async () => {
    if (isLoadingChecked || !user) {
      return;
    }

    try {
      setIsLoadingChecked(true);
      const products = basket.map(el => ({ ...el, checked: !isAllSelected }));
      await BasketService.addProductToBasket(user.id, products);
      setBasket(prev => prev.map(el => ({ ...el, checked: !isAllSelected })));
    } finally {
      setIsLoadingChecked(false);
    }
  };

  const handleClearBasket = async () => {
    if (isLoading || !user?.id) {
      return;
    }

    try {
      setIsLoading(true);
      await BasketService.addProductToBasket(
        user.id,
        basket.map(el => ({ ...el, amount: 0, removed: true })),
      );
      setBasket([]);
    } finally {
      setIsLoading(false);
    }
  };

  const selectOne = async (product: ProductType) => {
    if (isLoadingChecked || !user) {
      return;
    }

    try {
      setIsLoadingChecked(true);

      await BasketService.addProductToBasket(user.id, [{ ...product, checked: !product.checked }]);
      setBasket(prev =>
        prev.map(el => (el.id === product.id ? { ...el, checked: !product.checked } : el)),
      );
    } finally {
      setIsLoadingChecked(false);
    }
  };

  const removeFromBasket = async (product: ProductType) => {
    if (!user?.id) {
      return;
    }

    try {
      await BasketService.addProductToBasket(user.id, [{ ...product, amount: 0, removed: true }]);

      setBasket(prev => prev.filter(el => el.id !== product.id));
    } catch (e) {}
  };

  const onFavorite = (product: ProductType) => {
    setBasket(prev =>
      prev.map(el => (el.id === product.id ? { ...el, favorite: !el.favorite } : el)),
    );
  };
  
  const content = (
    <>
      <div className="flex-1">
        <div className="flex items-center justify-between pb-3 border-bgHover max-d-xs:mb-2 max-d-xs:border-b max-d-xs:border-b-1">
          <div className="group flex cursor-pointer items-center" onClick={selectAll}>
            <CheckBox
              isActive={isAllSelected}
              size="m"
              activeBorder="primary"
              classNames="border-primary-100"
            />
            <div className="font-default-medium ml-2 text-sm">Выбрать все</div>
          </div>

          <Button
            classNames="group hover:text-primary-100 transition-colors"
            variant="transparent"
            size="sm"
            onClick={handleClearBasket}
          >
            <TrashXIcon className="me-2 h-4 w-4" />
            Очистить корзину
          </Button>
        </div>

        {basket.map(product => (
          <div key={product.id} className="ps-3">
            <BasketItem
              product={product}
              onFavorite={onFavorite}
              select={selectOne.bind(null, product)}
              remove={removeFromBasket.bind(null, product)}
            />
          </div>
        ))}
      </div>
      <div className="flex flex-col-reverse items-center justify-between d-xs:hidden d-xs:flex-row">
        <div className="hidden d-xs:block">
          <div className="font-default-medium text-sm">{`${summ.count} ${declOfNum(summ.count, ['товар', 'товара', 'товаров'])}`}</div>
          <div className="font-default-semibold ml-auto text-xl">{`${addSpaces(summ.price)} ₽`}</div>
        </div>
        <div className="w-full">
          {/*<SberSpasiboBlock classNames="mb-4" />*/}
          <div className="flex max-d-xs:mt-4">
            <div className="d-xs:hidden">
              <div className="font-default-medium text-sm">{`${summ.count} ${declOfNum(summ.count, ['товар', 'товара', 'товаров'])}`}</div>
              <div className="font-default-semibold ml-auto text-xl">{`${addSpaces(summ.price)} ₽`}</div>
            </div>
            <Button
              classNames="d-xs:w-full w-1/2 ml-auto"
              variant="primary"
              disabled={isLoading}
              onClick={handleGetToken}
            >
              {isLoading ? <Loader classNames="w-5 h-5" /> : 'Оформить заказ'}
            </Button>
          </div>
        </div>
      </div>
      <div className="hidden w-96 flex-shrink-0 pl-8 d-xs:block">
        <div className="font-default-medium mb-8 flex text-sm">
          <div>{`${summ.count} ${declOfNum(summ.count, ['товар', 'товара', 'товаров'])}`}</div>
          <div className="ml-auto">{`${addSpaces(+summ.price.toFixed(2))} ₽`}</div>
        </div>

        {/*<SberSpasiboBlock classNames="mb-8" />*/}

        <div className="font-default-medium mb-8 flex items-center text-xl">
          <div>Итого:</div>
          <div className="ml-auto ">{`${addSpaces(+summ.price.toFixed(2))} ₽`}</div>
        </div>

        <Button
          classNames="w-full"
          variant={!basket.some(el => el.checked) ? 'disabled' : 'primary'}
          size="lg"
          disabled={isLoading || !basket.some(el => el.checked)}
          onClick={handleGetToken}
        >
          {isLoading ? <Loader classNames="w-5 h-5" /> : 'Перейти к оформлению'}
        </Button>
      </div>
    </>
  );

  return (
    <Container
      head={
        <div className="flex flex-row justify-between">
          <div className="font-default-medium mb-0 text-3xl d-xs:text-4xl">Корзина</div>
          {/* тут хардкод по ширине, не знаю, как иначе:) */}
          {!!basket.length && !isBasketLoading && (
            <div className="font-default-medium flex w-[352px] items-center text-xl max-t-lg:hidden">
              <div>Итого:</div>
              <div className="ml-auto ">{`${addSpaces(+summ.price.toFixed(2))} ₽`}</div>
            </div>
          )}
        </div>
      }
      classNames="flex flex-col gap-[40px] d-xs:gap-[100px]"
    >
      {isBasketLoading ? (
        <div className="flex flex-1 items-center justify-center">
          <Loader classNames="w-32 h-32 text-primary-100" />
        </div>
      ) : null}

      {!basket.length && !isBasketLoading ? (
        <div className="mx-auto flex w-fit flex-col items-center py-8">
          <div className="mb-11 h-28 w-28">
            <EmptyShoppingCartIcon/>
          </div>

          <div className="font-default-medium mb-16">В корзине пусто</div>

          {!user && (
            <Button
              classNames="w-48 h-10"
              size={undefined}
              variant="primary"
              onClick={() => navigate(routesEnum.LOGIN)}
            >
              Войти
            </Button>
          )}

          {user && !basket.length ? (
            <Button
              classNames="w-48 h-10"
              size={undefined}
              variant="primary"
              onClick={() => navigate(routesEnum.CATALOG)}
            >
              В каталог
            </Button>
          ) : null}
        </div>
      ) : null}

      {!isBasketLoading && basket.length ? (
        <>
          <WhiteBox classNames="d-xs:flex w-full flex-colflex-row hidden">{content}</WhiteBox>
          <div className="d-xs:hidden">{content}</div>
        </>
      ) : null}
    </Container>
  );
};

interface BasketItemProps {
  product: ProductType;
  select(): void;
  remove(): void;
  onFavorite(product: ProductType): void;
}
function BasketItem({ product, select, remove, onFavorite }: BasketItemProps) {
  const serviceActivationParam = product.parameters.find(
    (param) => param.parameterName === 'Сервис активации'
  );
  const regionActivationParam = product.parameters.find(
    (param) => param.parameterName === 'Регион'
  );
  
  const serviceActivationValue = serviceActivationParam?.parameterValues?.[0]?.valueName || '';
  const regionActivationValue = regionActivationParam?.parameterValues?.[0]?.valueName || '';  
  return (
    <div key={`basket-${product.id}`} className="flex py-4 d-xs:py-8 border-bgHover max-d-xs:mb-2 max-d-xs:border-b max-d-xs:border-b-1">
      <Link
        to={`/product/${product.id}`}
        className="group relative mr-4 h-20 w-20 flex-shrink-0 d-xs:mr-8 d-xs:h-28 d-xs:w-28"
      >
        <CheckBox
          isActive={product.checked}
          onClick={e => {
            e?.preventDefault();
            select();
          }}
          classNames="border-primary-100 absolute top-0 left-0 z-30 -translate-x-1/2 -translate-y-1/2"
          size="m"
          activeBorder="primary"
        />
        <img src={product.image} className='rounded-sm aspect-square'/>
      </Link>

      <div className="d-xs:hidden flex-1">
        <div className="flex items-center mb-4">
          <div className="mr-2 font-default-medium text-lg leading-5 d-xs:text-xl">
            {`${addSpaces(+(+product.newPrice.replace(',', '.') * product.amount).toFixed(2))} ₽`}
          </div>
          {/*<SberSpasibo price={230} classNames="mb-1" />*/}
          <div className="mb-3 flex items-center gap-2">
            {product.discount && product.discount.toString() !== '0' && (
              <DiscountPercent percent={product.discount} />
            )}
            {product.discount && product.discount.toString() !== '0' && (
              <DiscountPrice price={product.oldPrice} />
            )}
          </div>
        </div>
        <div className="font-default-medium break-words break-all text-sm mb-2">
          {product.name}
        </div>
        <div className="font-default-regular">
          <div className="break-words break-all text-sm text-textLighter">Сервис активации: {serviceActivationValue}</div>
          <div className="break-words break-all text-sm text-textLighter">Регион активации: {regionActivationValue}</div>
        </div>
        <ProductAddButton product={product} className="mt-4" />
      </div>

      <div className="mr-8 hidden w-1/3 flex-col justify-end gap-[0.75rem] d-xs:flex">
        <div className="font-default-medium break-words break-all text-sm">
          {product.name}
        </div>
        <div className="font-default-regular">
          <div className="break-words break-all text-sm">Сервис активации: {serviceActivationValue}</div>
          <div className="break-words break-all text-sm">Регион активации: {regionActivationValue}</div>
        </div>
      </div>

      <div className="hidden w-[22.5rem] min-w-[22.5rem] items-center gap-10 d-xs:flex">
        <ProductAddButton product={product} />

        <div className="flex flex-row items-center gap-3">
          <div className="flex items-center">
            <div className="font-default-semibold whitespace-nowrap text-base">{`${addSpaces(+(+product.newPrice.replace(',', '.') * product.amount).toFixed(2))} ₽`}</div>
            {/*<SberSpasibo price={230} classNames="mb-1" />*/}
          </div>

          {product.discount && product.discount.toString() !== '0' && (
            <DiscountPrice price={product.oldPrice} size="m" />
          )}
        </div>
      </div>

      <div className="ml-4 flex flex-shrink-0 flex-col justify-between">
        <FavoriteButton
          data={product}
          onClick={onFavorite}
          size="m"
          classNames="max-d-xs:h-5 max-d-xs:w-5"
        />
        <button className="group" onClick={remove}>
          <TrashIcon className="h-6 w-6 fill-textLighter transition-all group-hover:fill-primary-100 max-d-xs:h-5 max-d-xs:w-5" />
        </button>
      </div>
    </div>
  );
}
