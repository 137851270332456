import { FC } from 'react';
import Select from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import cn from 'classnames';

interface AppSelectProps extends StateManagerProps {}

export const AppSelect: FC<AppSelectProps> = ({ ...props }) => {
  return (
    <Select
      isSearchable={false}
      {...props}
      classNames={{
        container: () => 'relative w-full',
        control: state => cn(
          'font-default-regular text-sm rounded-lg',
          'py-2 px-4 flex items-center content-center flex-wrap justify-between',
          'h-9 outline-0 relative bg-bgPrimary cursor-pointer',
          state.menuIsOpen && 'shadow-100'
        ),
        indicatorSeparator: () => 'hidden',
        menuList: () => cn(
          'max-h-[300px] overflow-y-auto relative p-0 shadow-100 rounded-lg',
          'my-2'
        ),
        option: state =>
          cn(
            'font-default-regular text-sm',
            'block w-full select-none py-2 px-3.5 cursor-pointer',
            state.isFocused ? 'bg-bgHover' : 'bg-bgPrimary',
            state.isSelected ? 'text-primary-400' : 'text-textPrimary',
          ),
      }}
      unstyled
    />
  );
};
