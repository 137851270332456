import { FC } from 'react';
import cn from 'classnames';

interface DiscountPercentProps {
  percent: number;
  big?: boolean;
}

export const DiscountPercent: FC<DiscountPercentProps> = ({ percent, big }) => {
  return (
    <div className="w-fit -skew-x-12 rounded bg-red-200 px-1">
      <div
        className={cn(
          {
            'text-xs': !Boolean(big),
            'text-base': Boolean(big),
          },
          'font-default-semibold skew-x-12 text-white',
        )}
      >{`-${percent}%`}</div>
    </div>
  );
};
