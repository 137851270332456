import { FC } from 'react';
import cn from 'classnames';
import { addSpaces } from 'src/shared/lib/utils';

interface DiscountPriceProps {
  price: string;
  size?: 's' | 'm' | 'l';
}

export const DiscountPrice: FC<DiscountPriceProps> = ({ price, size = 's' }) => {
  return (
    <div
      className={cn(
        { 'text-xs': size === 's', 'text-base': size === 'm', 'text-lg': size === 'l' },
        'font-default-semibold relative w-fit whitespace-nowrap text-grey-500',
        'line-through decoration-red-200 decoration-2',
      )}
    >{`${addSpaces(+price.replace(',', '.'))} ₽`}</div>
  );
};
