import { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'src/app/providers/UserProvider';
import { Container } from 'src/entity/Container';
import { ProfileNavigation } from 'src/feature/ProfileNavigation';
import AuthService from 'src/shared/api/auth/AuthService';
import { ReactComponent as ArrowSmallIcon } from 'src/shared/assets/icons/kit/arrow-small.svg';
import { routesEnum } from 'src/shared/const';
import { setSuccess } from 'src/shared/lib/utils';

export const CustomizationPage: FC = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleLogout = async () => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);

      await AuthService.logout();

      setUser(null);
      navigate(routesEnum.LOGIN);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveUser = async () => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);

      await AuthService.removeUser();

      setUser(null);
      navigate(routesEnum.LOGIN);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogoutReverse = async () => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);

      await AuthService.logoutReverse();

      setSuccess('Вы вышли со всех аккаунтов');
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const list = [
    {
      label: 'Выйти из профиля на этом устройстве',
      onClick: handleLogout,
    },
    {
      label: 'Выйти на всех устройствах кроме этого',
      onClick: handleLogoutReverse,
    },
    {
      label: 'Удалить профиль',
      onClick: handleRemoveUser,
    },
  ];

  return (
    <Container>
      <div className="flex flex-1">
        <div className="hidden h-full w-72 flex-shrink-0 d-xs:block">
          <ProfileNavigation />
        </div>

        <div className="flex flex-1 flex-col d-xs:ml-5">
          <div className="font-default-medium mb-10 text-3xl">Настройки профиля</div>

          <div className="font-default-semibold mb-5 text-xl d-xs:text-2xl">Вход и удаление</div>

          {list.map(({ label, onClick }) => (
            <button
              className="group flex w-full items-center border-b border-bgPrimary py-6"
              onClick={onClick}
            >
              <div className="font-default-medium text-textLighter group-hover:text-textPrimary d-xs:text-xl">
                {label}
              </div>
              <ArrowSmallIcon
                className={
                  'ml-auto mr-2 h-4 w-4 rotate-180 stroke-grey-500 group-hover:stroke-grey-900'
                }
              />
            </button>
          ))}
        </div>
      </div>
    </Container>
  );
};
