import { FC, useEffect, useState, ChangeEvent } from 'react';
import { Interval } from 'src/shared/types';

import './styles.css';

interface RangeSliderProps {
  min: number;
  max: number;
  value: Interval;
  step: number;
  onChange: (value: Interval) => void;
}

export const RangeSlider: FC<RangeSliderProps> = ({ min, max, value, step, onChange }) => {
  const [minValue, setMinValue] = useState(value ? value.min : min);
  const [maxValue, setMaxValue] = useState(value ? value.max : max);

  useEffect(() => {
    if (value) {
      setMinValue(value.min);
      setMaxValue(value.max);
    }
  }, [value]);

  const handleMinChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newMinVal = Math.min(+e.target.value, maxValue - step);
    setMinValue(newMinVal);
  };

  const handleMaxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newMaxVal = Math.max(+e.target.value, minValue + step);
    setMaxValue(newMaxVal);
  };

  const handleChangeComplete = () => {
    onChange({ min: minValue, max: maxValue });
  };

  const minPos = ((minValue - min) / (max - min)) * 100;
  const maxPos = ((maxValue - min) / (max - min)) * 100;

  return (
    <div className="wrapper">
      <div className="input-wrapper">
        <input
          className="input"
          type="range"
          value={minValue}
          min={min}
          max={max}
          step={step}
          onChange={handleMinChange}
          onMouseUp={handleChangeComplete}
          onTouchEnd={handleChangeComplete}
        />
        <input
          className="input"
          type="range"
          value={maxValue}
          min={min}
          max={max}
          step={step}
          onChange={handleMaxChange}
          onMouseUp={handleChangeComplete}
          onTouchEnd={handleChangeComplete}
        />
      </div>

      <div className="control-wrapper">
        <div className="control " style={{ left: `${minPos}%` }} />
        <div className="rail bg-textLighter">
          <div
            className="inner-rail bg-primary-200"
            style={{ left: `${minPos}%`, right: `${100 - maxPos}%` }}
          />
        </div>
        <div className="control" style={{ left: `${maxPos}%` }} />
      </div>
    </div>
  );
};
