import { FC, MouseEvent } from 'react';
import cn from 'classnames';
import { ProductType } from 'src/shared/api/catalog';
import CatalogService from 'src/shared/api/catalog/CatalogService';
import { ReactComponent as HeartIcon } from 'src/shared/assets/icons/kit/heart.svg';
import { ReactComponent as HeartFullIcon } from 'src/shared/assets/icons/kit/heart-full.svg';

interface FavoriteButtonProps {
  data: ProductType;
  onClick?: (data: ProductType) => void;
  text?: boolean;
  classNames?: string;
  size?: 's' | 'm' | 'l';
}

export const FavoriteButton: FC<FavoriteButtonProps> = ({
  data,
  onClick,
  text,
  classNames,
  size = 's',
}) => {
  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    try {
      if (data.favorite) {
        await CatalogService.deleteFavorite(data.id);
      } else {
        await CatalogService.setFavorite(data.id);
      }

      onClick && onClick(data);
    } catch (e) {}
  };

  return (
    <button className={cn('flex items-center', classNames)} onClick={handleClick}>
      <div
        className={cn({
          'h-4 w-4': size === 's',
          'h-6 w-6': size === 'm',
          'h-8 w-8': size === 'l',
        })}
      >
        {data.favorite ? (
          <HeartFullIcon className="fill-primary-100 hover:fill-primary-200" />
        ) : (
          <HeartIcon
            className={cn(
              data.favorite ? 'fill-primary-100' : 'fill-textLighter',
              'hover:fill-primary-100',
            )}
          />
        )}
      </div>
      {text && (
        <div className="font-default-medium ml-2 text-sm text-grey-500">
          {data.favorite ? 'В избранном' : 'В избранное'}
        </div>
      )}
    </button>
  );
};
