import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link, useNavigate, useLocation } from 'react-router-dom'; // Добавили useLocation
import cn from 'classnames';
import { BasketContext } from 'src/app/providers/BasketProvider';
import { UserContext } from 'src/app/providers/UserProvider';
import { ProfileNavigation } from 'src/feature/ProfileNavigation';
import { ReactComponent as BurgerIcon } from 'src/shared/assets/icons/kit/burger.svg';
import { ReactComponent as HomeIcon } from 'src/shared/assets/icons/kit/home.svg';
import { ReactComponent as CatalogIcon } from 'src/shared/assets/icons/kit/catalog.svg';
import { ReactComponent as CloseIcon } from 'src/shared/assets/icons/kit/close.svg';
import { ReactComponent as HeartIcon } from 'src/shared/assets/icons/kit/heart.svg';
import { ReactComponent as ShoppingCartIcon } from 'src/shared/assets/icons/kit/shopping-cart.svg';
import { routesEnum } from 'src/shared/const';
import { Button } from 'src/shared/ui';
import { Loader } from 'src/shared/ui/Loader';

export const Header: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { user, isUserLoading } = useContext(UserContext);
  const { basket } = useContext(BasketContext);

  const [isTop, setIsTop] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const checkScroll = () => setIsTop(window?.scrollY === 0);

  useEffect(() => {
    document.addEventListener('scroll', checkScroll);

    return () => {
      document.removeEventListener('scroll', checkScroll);
    };
  }, []);

  const iconStyles = 'w-5 h-5 fill-grey-500';

  const links = [
    { path: routesEnum.HOME, icon: <HomeIcon className={iconStyles} /> },
    { path: routesEnum.CATALOG, icon: <CatalogIcon className={iconStyles} /> },
    { path: routesEnum.FAVORITES, icon: <HeartIcon className={iconStyles} /> },
    { path: routesEnum.BASKET, icon: <ShoppingCartIcon className={iconStyles} /> },
  ];

  const fullAmount = useMemo(() => {
    if (!basket.length) {
      return 0;
    }

    return basket.reduce((sum, curr) => {
      return sum + curr.amount;
    }, 0);
  }, [basket]);

  const getActiveStyles = (path: string) =>
    pathname === path && 'bg-grey-200 hover:bg-grey-200 rounded-full';

  return (
    <header>
      <div className="hidden d-xs:block">
        <Button
          classNames="px-8 py-2 bg-bgPrimary fixed z-1000 top-4 left-1/2 -translate-x-1/2 hover:text-primary-100 transition-colors"
          disabled
        >
          <div className="font-default-semibold text-xl">ГеймерсХаб</div>
        </Button>

        {user && (
          <Button
            classNames="fixed z-1000 bg-bgPrimary top-4 right-4"
            onClick={() => {
              if (!isUserLoading && !user) {
                navigate(routesEnum.LOGIN);
              }
              if (user) {
                navigate(routesEnum.PROFILE_ORDERS);
              }
            }}
          >
            {isUserLoading && <Loader classNames="w-4 h-4 mx-8" />}
            {!isUserLoading && user && (
              <>
                {user.name}
              </>
            )}
            {!isUserLoading && !user && 'Войти'}
          </Button>
        )}
      </div>

      <div className="h-12 min-h-12 d-xs:hidden">
        <div
          className={cn(
            isTop ? 'relative border-bgHover' : 'fixed w-full border-bgHover',
            'z-1000 flex h-12 min-h-12 items-center border-b bg-body px-4',
          )}
        >
          <button className="mr-3 h-5 w-5" onClick={() => setIsMenuOpen(true)}>
            <BurgerIcon className="fill-grey-500 p-0.5" />
          </button>

          <div className="font-default-semibold text-primary-100">ГеймерсХаб</div>

          {user && (
            <div className="ml-auto flex gap-2">
              {links.map(({ path, icon }) => (
                <Link
                  to={path}
                  key={`header-link-${path}`}
                  className={cn(
                    'relative flex h-8 w-8 items-center justify-center transition-colors',
                    getActiveStyles(path)
                  )}
                >
                  {icon}
                  {path === routesEnum.BASKET && fullAmount ? (
                    <div
                      className={cn(
                        'font-default-medium absolute -right-0.5 -top-0.5 rounded-full px-1.5',
                        'bg-primary-100 py-0.5 text-extra-xs text-white',
                      )}
                    >
                      {fullAmount}
                    </div>
                  ) : null}
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>

      {isMenuOpen &&
        createPortal(
          <div className="fixed inset-0 z-2000 bg-bgPrimary text-textPrimary p-4">
            <div className="mb-8 flex items-center justify-between">
              <div className="font-default-medium text-xl">Меню</div>

              <button className="h-5 w-5 text-textPrimary" onClick={() => setIsMenuOpen(false)}>
                <CloseIcon className="p-0.5" />
              </button>
            </div>

            <ProfileNavigation onClose={() => setIsMenuOpen(false)} />
          </div>,
          document.body,
        )}
    </header>
  );
};
