import { FC } from 'react';
import { Container } from 'src/entity/Container';
import { BannersList } from 'src/feature/BannersList';
import { ProductGridCard } from 'src/feature/ProductGridCard';
import useBanners from 'src/hooks/useBanners';
import { ProductType } from 'src/shared/api/catalog';
import { Loader } from 'src/shared/ui/Loader';
import OnlineEvents from 'src/entity/OnlineEvents';
import { useProducts } from './hooks/useProducts';

const links: {name: string; target: string}[] = [
  {name: 'Мегамаркет', target: 'https://megamarket.ru/catalog/tovary-dlya-geymerov/'},
  {name: 'Пополнить Steam', target: 'https://hi-tech.mail.ru/news/115268-sber-vernul-rublevoe-popolnenie-koshelkov-steam'},
  {name: 'Адвент-календарь', target: 'https://t.me/smkt_advent_bot'},
  {name: 'Okko', target: 'https://okko.sport/sport_collection/genre_esports'},
]

export const HomePage: FC = () => {
  const { banners } = useBanners(2);

  const { isLoading, products, setProducts } = useProducts();

  const onFavorite = (product: ProductType) => {
    setProducts(prev =>
      prev.map(el => (el.id === product.id ? { ...el, favorite: !el.favorite } : el)),
    );
  };

  return (
    <Container classNames="pb-20">
      <div className="flex flex-1 flex-col">
        <div className="w-full overflow-x-scroll mb-8 scrollbar-hide">
          <div className="w-min m-auto flex flex-row gap-1.5">
            {links.map(item =>
              <a
                key={item.name}
                className={
                  'bg-bgPrimary rounded-3xl px-5 py-1.5 whitespace-nowrap' +
                  ' transition-colors hover:bg-bgHover font-default-regular'
                }
                target="_blank"
                href={item.target}
              >{item.name}</a>,
            )}
          </div>
        </div>

        {banners.length > 0 && (
          <div className="aspect-banner-home mb-16 w-full overflow-hidden">
            <BannersList list={banners} />
          </div>
        )}

        <div className="mb-16 flex flex-col gap-7">
          <div className="font-default-medium text-3xl">Бестселлеры</div>
          <div className="w-full flex-1">
            {isLoading ? (
              <Loader classNames="mx-auto mt-40 w-20 h-20 text-primary-100" />
            ) : products.length ? (
              <div className="flex flex-wrap d-xs:gap-2.5 justify-center">
                {products.slice(0, 15).map(el => (
                  <ProductGridCard onFavorite={onFavorite} key={el.id} data={el} />
                ))}
              </div>
            ) : (
              <div className="font-default-medium mx-auto mt-40">Товары не найдены</div>
            )}
          </div>
        </div>

        <OnlineEvents className="mb-8 flex flex-col gap-7"/>

      </div>
    </Container>
  );
};
