import { FC, ReactNode } from 'react';
import cn from 'classnames';

interface WhiteBoxProps {
  classNames?: string;
  children: ReactNode;
}

export const WhiteBox: FC<WhiteBoxProps> = ({ classNames, children }) => {
  return <div className={cn('rounded-xl bg-bgPressed p-5 shadow-100', classNames)}>{children}</div>;
};
