import { FC, useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { UserContext } from 'src/app/providers/UserProvider';
import AuthService from 'src/shared/api/auth/AuthService';
import TokenService from 'src/shared/api/token/TokenService';
import { ReactComponent as CheckIcon } from 'src/shared/assets/icons/kit/check.svg';
import { routesEnum } from 'src/shared/const';
import { Button, CheckBox, Input, Title } from 'src/shared/ui';
import * as yup from 'yup';

type LoginType = {
  login: string;
  password: string;
};

const schema = yup.object().shape({
  login: yup.string().required('Заполните поле'),
  password: yup.string().required('Заполните поле'),
});

export const LoginPage: FC = () => {
  const navigate = useNavigate();
  const { setUser, user, isUserLoading } = useContext(UserContext);

  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (!isUserLoading && user) {
      navigate(routesEnum.CATALOG);
    }
  }, [isUserLoading, user]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginType>({ mode: 'onBlur', resolver: yupResolver(schema) });

  const onSubmit: SubmitHandler<LoginType> = async params => {
    if (isSubmitting) {
      return;
    }

    try {
      const loginRes = await AuthService.login(params);
      TokenService.setTokens(loginRes.data);

      const userRes = await AuthService.getUser();
      setUser(userRes.data);
    } catch (e) {
    } finally {
      navigate(routesEnum.HOME);
    }
  };

  return (
    <div className="flex h-full w-full flex-col items-center justify-center p-4">
      <Title variant="sm" classNames="mb-12">
        Вход
      </Title>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mb-10 flex w-full max-w-[32rem] flex-col items-center"
      >
        <Input
          classNames={cn(
            'mb-6 bg-bgPrimary rounded-lg transition-all',
            'focus-within:outline focus-within:outline-1 focus-within:outline-textLighter'
          )}
          register={register('login')}
          error={errors.login?.message}
          placeholder="Логин"
        />

        <Input
          classNames={cn(
            'mb-10 bg-bgPrimary rounded-lg transition-all',
            'focus-within:outline focus-within:outline-1 focus-within:outline-textLighter'
          )}
          register={register('password')}
          error={errors.password?.message}
          placeholder="Пароль"
          type="password"
        />

        <Button classNames="w-80" variant="primary" size="lg">
          Войти
        </Button>
      </form>

      <div
        className="font-default-regular mb-2.5 flex cursor-pointer items-center gap-3"
        onClick={() => setCheck(prev => !prev)}
      >
        <CheckBox size="m" isActive={check}/>
        <div>Не выходить из системы</div>
      </div>

      {/*<div className="font-default-regular mb-2.5 flex">*/}
      {/*  <div className="mr-2 text-black-100">Забыли пароль?</div>*/}
      {/*  <Link to={routesEnum.REGISTER} className="text-primary-100 underline">*/}
      {/*    Восстановить*/}
      {/*  </Link>*/}
      {/*</div>*/}

      {/*<div className="font-default-regular flex">*/}
      {/*  <div className="mr-2 text-black-100">Нет аккаунта?</div>*/}
      {/*  <Link to={routesEnum.REGISTER} className="text-primary-100 underline">*/}
      {/*    Зарегистрироваться*/}
      {/*  </Link>*/}
      {/*</div>*/}
    </div>
  );
};
