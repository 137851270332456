import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'src/app/providers/UserProvider';
import CatalogService from 'src/shared/api/catalog/CatalogService';
import { BannerType } from 'src/shared/store';

export default function useBanners(pageId: number) {
  const { user } = useContext(UserContext);

  const [banners, setBanners] = useState<BannerType[]>([]);
  const [isBannersLoading, setIsBannersLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!user) {
      return;
    }

    (async () => {
      try {
        const { data } = await CatalogService.getBanners(pageId);
        setBanners(data);
      } catch (e) {
      } finally {
        setIsBannersLoading(false);
      }
    })();
  }, [user, pageId]);

  return { banners, isBannersLoading };
}
