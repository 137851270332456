import { useContext, useEffect, useState } from 'react';
import { ProductType } from 'src/shared/api/catalog';
import CatalogService from 'src/shared/api/catalog/CatalogService';
import { UserContext } from 'src/shared/store';

export const useProducts = () => {
  const { user } = useContext(UserContext);

  const [products, setProducts] = useState<ProductType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    CatalogService.getTrendingProducts()
      .then(res => setProducts(res.data))
      .catch(() => undefined)
      .finally(() => setIsLoading(false));
  }, [user]);

  return { isLoading, products, setProducts };
};
