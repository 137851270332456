import { FC } from 'react';
import cn from 'classnames';

interface LoaderProps {
  strokeWidth?: string;
  classNames?: string;
}

export const Loader: FC<LoaderProps> = ({ strokeWidth, classNames }) => {
  return (
    <div className={cn('relative', classNames)}>
      <svg className="before:block before:pt-[100] before:content-['']" viewBox="25 25 50 50">
        <circle
          className="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth={strokeWidth || '3'}
          strokeMiterlimit="10"
          stroke="currentColor"
        />
      </svg>
    </div>
  );
};
