import { FC, useContext, useEffect, useMemo, useState, TouchEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { UserContext } from 'src/app/providers/UserProvider';
import { Container } from 'src/entity/Container';
import { FavoriteButton } from 'src/entity/FavoriteButton';
import { ProductAddButton } from 'src/entity/ProductAddButton';
import { ProductGridCard } from 'src/feature/ProductGridCard';
import { ProductType } from 'src/shared/api/catalog';
import CatalogService from 'src/shared/api/catalog/CatalogService';
import { ReactComponent as ArrowIcon } from 'src/shared/assets/icons/kit/arrow.svg';
import { routesEnum } from 'src/shared/const';
import { addSpaces } from 'src/shared/lib/utils';
import { Button, DiscountPrice, Img, WhiteBox } from 'src/shared/ui';
import { Loader } from 'src/shared/ui/Loader';

export const ProductPage: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useContext(UserContext);

  const [currentImage, setCurrentImage] = useState(0);
  const [product, setProduct] = useState<ProductType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [similarProducts, setSimilarProducts] = useState<ProductType[]>([]);

  const [touchStartX, setTouchStartX] = useState<number | null>(null); // Для свайпа

  const carousel = useMemo(() => {
    if (!product) {
      return [''];
    }
    const { image, carouselImages } = product;

    const res: string[] = [];
    if (image) {
      res.push(image);
    }
    if (carouselImages?.length) {
      res.push(...carouselImages);
    }

    return res?.length ? res : [''];
  }, [product]);

  useEffect(() => {
    if (!id || !user?.id) return;

    (async () => {
      try {
        setIsLoading(true);
        const { data } = await CatalogService.getProduct(id, user.id);
        setProduct(data[0]);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id, user]);

  useEffect(() => {
    if (!product || !id || !user?.id) return;

    (async () => {
      try {
        const { data } = await CatalogService.getSimilarProducts(id, user.id);
        setSimilarProducts(data);
      } catch (e) {}
    })();
  }, [product]);

  const onFavorite = (item: ProductType) => {
    setSimilarProducts(prev =>
      prev.map(el => (el.id === item.id ? { ...el, favorite: !el.favorite } : el)),
    );
  };

  const onFavoriteSingle = () => {
    setProduct(prev => (prev ? { ...prev, favorite: !prev.favorite } : prev));
  };

  if (!isLoading && !product) {
    return (
      <div className="mx-auto mt-40 flex w-40 flex-col items-center">
        <div className="font-default-medium mb-8">Товар не найден</div>
        <Button variant="primary" classNames="w-full" onClick={() => navigate(routesEnum.CATALOG)}>
          В каталог
        </Button>
      </div>
    );
  }

  if (isLoading) {
    return <Loader classNames="w-20 h-20 mx-auto mt-20 text-primary-100" />;
  }

  const handleNext = () => {
    setCurrentImage(prev => (prev < carousel.length - 1 ? prev + 1 : 0));
  };

  const handlePrev = () => {
    setCurrentImage(prev => (prev !== 0 ? prev - 1 : carousel.length - 1));
  };

  const handleTouchStart = (e: TouchEvent) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e: TouchEvent) => {
    if (touchStartX !== null) {
      const deltaX = e.changedTouches[0].clientX - touchStartX;
      if (deltaX > 50) {
        handlePrev();
      } else if (deltaX < -50) {
        handleNext();
      }
    }
    setTouchStartX(null);
  };

  return (
    product && (
      <>
        {/* Мобильная версия */}
        <div className="relative flex w-full flex-col items-center d-xs:hidden">
          <div
            className="group relative h-96 w-full"
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            {/* Оверлеи для переключения */}
            <div className="absolute left-0 top-0 z-10 h-full w-1/2 pointer-events-auto" onClick={handlePrev} />
            <div className="absolute right-0 top-0 z-10 h-full w-1/2 pointer-events-auto" onClick={handleNext} />

            <div className="mx-auto aspect-square h-full relative">
              <Img src={carousel[currentImage]} noBg classNames="pointer-events-none" />

              {/* Стрелка назад внутри контейнера изображения */}
              <button
                onClick={() => navigate(-1)}
                className="absolute left-3 top-3 flex h-8 w-8 items-center justify-center rounded-full bg-bgPrimary z-50 pointer-events-auto"
              >
                <ArrowIcon className="h-5 w-5 fill-grey-500" />
              </button>

              {/* Кнопка избранного внутри контейнера изображения */}
              <div
                className="absolute right-3 top-3 flex h-8 w-8 items-center justify-center rounded-full bg-bgPrimary z-50 pointer-events-auto"
              >
                <FavoriteButton onClick={onFavoriteSingle} data={product} />
              </div>
            </div>
          </div>

          <div className="flex gap-2 py-4">
            {carousel.map((_, i) => (
              <div
                onClick={() => setCurrentImage(i)}
                className={cn(
                  {
                    'bg-primary-100': i === currentImage,
                    'bg-grey-400': i !== currentImage,
                  },
                  'h-1.5 w-1.5 cursor-pointer rounded-full transition-all',
                )}
                key={`img-1-${i}`}
              />
            ))}
          </div>
        </div>

        <Container classNames="flex-1">
          <div className="mb-16 hidden h-32 w-full items-center rounded-3xl bg-bgPrimary px-4 d-xs:flex">
            <div className="h-16 w-16">
              <Img src={product.shop.photo} noBg />
            </div>

            <div className="font-default-medium ml-8 text-3xl">{product.shop.name}</div>
          </div>

          <div className="font-default-medium mb-4 text-lg d-xs:mb-8 d-xs:text-3xl flex items-center gap-4">
            {product.name}
          </div>

          <div className="flex flex-col d-xs:flex-row">
            <div className="hidden flex-shrink-0 flex-col gap-2 d-xs:flex">
              {carousel.map((img, i) => (
                <div
                  onClick={() => setCurrentImage(i)}
                  className={cn(
                    {
                      'border-primary-100': i === currentImage,
                      'hover:border-primary-100': i !== currentImage,
                    },
                    'h-12 w-12 cursor-pointer rounded-xl border p-1 transition-all',
                  )}
                  key={`img-${i}`}
                >
                  <Img src={img} classNames="rounded-lg pointer-events-none" />
                </div>
              ))}
            </div>

            {/* Большое изображение на десктопе */}
            <div
              className="relative group mx-2 mr-8 hidden aspect-square h-[26rem] w-[26rem] flex-shrink-0 d-xs:block"
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            >
              {/* Иконка сердечка поверх изображения */}
              <div className="absolute top-2 right-2 z-50 pointer-events-auto">
                <FavoriteButton onClick={onFavoriteSingle} data={product} />
              </div>
              <Img src={carousel[currentImage] || ''} classNames="pointer-events-none" />
            </div>

            <div className="mb-8 d-xs:mb-0 d-xs:mr-8 d-xs:flex-1">
              {product.parameters.map(parameter => (
                <div
                  key={parameter.parameterId}
                  className="font-default-medium mb-2 flex text-sm"
                >
                  {`${parameter.parameterName}: ${parameter.parameterValues
                    .map(el => el.valueName)
                    .join(', ')}`}
                </div>
              ))}
              <div className="font-default-regular text-sm">{product.description}</div>
            </div>

            <WhiteBox classNames="flex-shrink-0 d-xs:w-96 w-full h-fit">
              <div className="mb-5 flex items-center gap-3">
                <div className="font-default-medium text-4xl">
                  {`${addSpaces(+product.newPrice.replace(',', '.'))} ₽`}
                </div>
                {product.discount && product.discount.toString() !== '0' && (
                  <DiscountPrice size="l" price={product.oldPrice} />
                )}
              </div>

              <ProductAddButton product={product} className="mt-4" />
            </WhiteBox>
          </div>
        </Container>

        {similarProducts?.length ? (
          <Container classNames="w-full mt-5 d-xs:mt-8">
            <div className="font-default-medium mb-3 pl-2 text-xl d-xs:text-2xl">
              Похожие товары
            </div>
            <div className="flex w-full flex-wrap">
              {similarProducts.map(el => (
                <ProductGridCard onFavorite={onFavorite} key={el.id} data={el} />
              ))}
            </div>
          </Container>
        ) : null}
      </>
    )
  );
};
