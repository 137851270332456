import { FC } from 'react';
import cn from 'classnames';
import { ProductType } from 'src/shared/api/catalog';
import { ReactComponent as MinusIcon } from 'src/shared/assets/icons/kit/minus.svg';
import { ReactComponent as PlusIcon } from 'src/shared/assets/icons/kit/plus.svg';
import { useAddToBasket } from 'src/shared/lib/hooks';
import { Button } from 'src/shared/ui';
import { Loader } from 'src/shared/ui/Loader';

interface ProductAddButtonProps {
  product: ProductType;
  className?: string;
}

export const ProductAddButton: FC<ProductAddButtonProps> = ({ product, className }) => {
  const { addToBasket, removeOneFromBasket, isLoading, amount } = useAddToBasket(product);

  return amount ? (
    <div className={cn(
      'flex h-9 w-fit items-center rounded-full bg-bgPrimary hover:bg-bgHover transition-colors',
      className
    )}>
      <button className="flex h-full items-center px-4" onClick={removeOneFromBasket}>
        <MinusIcon className="fill-primary-100 h-3 w-3" />
      </button>
      <div className="flex w-9 min-w-9 justify-center">
        {isLoading ? (
          <Loader classNames="w-5 h-5 text-primary-100" />
        ) : (
          <div
            className="font-default-medium text-center text-textPrimary"
          >{amount}</div>
        )}
      </div>
      <button className="flex h-full items-center px-4" onClick={addToBasket}>
        <PlusIcon className="fill-primary-100 h-3 w-3" />
      </button>
    </div>
  ) : (
    <Button variant="primary" classNames={cn('m-md:w-44 w-full', className)} onClick={addToBasket}>
      {isLoading ? <Loader classNames="w-5 h-5" /> : 'Купить'}
    </Button>
  );
};
