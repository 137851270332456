import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { FavoriteButton } from 'src/entity/FavoriteButton';
import { ProductAddButton } from 'src/entity/ProductAddButton';
import { ProductType } from 'src/shared/api/catalog';
import { addSpaces } from 'src/shared/lib/utils';
import { CardImgCarousel, DiscountPercent, DiscountPrice } from 'src/shared/ui';

interface ProductGridCardProps {
  data: ProductType;
  onFavorite: (data: ProductType) => void;
}

export const ProductGridCard: FC<ProductGridCardProps> = ({ data, onFavorite }) => {
  const {
    name,
    id,
    newPrice,
    carouselImages,
    rating,
    commentsCount,
    image,
    parameters,
    oldPrice,
    discount,
  } = data;
  const owner = parameters.find(el => el.parameterName === 'Разработчик');

  const carousel = useMemo(() => {
    const res: string[] = [];

    if (image) {
      res.push(image);
    }

    if (carouselImages?.length) {
      res.push(...carouselImages);
    }

    return res?.length ? res : [''];
  }, [carouselImages, image]);

  return (
    <div
      className={
        'flex min-h-36 w-1/2 max-w-80 flex-col px-3 py-4 ' +
        'd-xs:max-w-auto d-xs:mb-6 d-xs:w-[17rem] d-xs:rounded-2xl d-xs:border-transparent ' +
        'relative d-xs:p-2.5 d-xs:hover:shadow-100'
      }
    >
      <div className="absolute right-5 top-6 z-30 rounded-full bg-bgPrimary p-1.5">
        <FavoriteButton onClick={onFavorite} data={data} />
      </div>

      <Link
        to={`/product/${id}`}
        className="mb-1 aspect-square w-full flex-shrink-0 overflow-hidden rounded-lg"
      >
        <CardImgCarousel images={carousel} />
      </Link>

      <div className="flex flex-1 flex-col justify-between">
        <div>
          <div className="h-5">
            {discount.toString() !== '0' && discount ? (
              <DiscountPercent percent={discount} />
            ) : null}
          </div>

          <div className="flex items-center">
            <div className="font-default-medium mr-2">{`${addSpaces(+newPrice.replace(',', '.'))} ₽`}</div>
            {/*<SberSpasibo discount={10} price={200} />*/}
          </div>

          <div className="h-4">
            {discount.toString() !== '0' && discount && <DiscountPrice price={oldPrice} />}
          </div>

          <Link to={`/product/${id}`} className="font-default-medium mb-2 block truncate text-xs">
            {name}
          </Link>

          {/* Тут были звездочки */}
          {/*<div className="mb-2 flex items-center">*/}
          {/*  <Stars rate={rating} />*/}
          {/*  <div className="font-default-medium ml-2 mt-1 text-xs">{commentsCount}</div>*/}
          {/*</div>*/}

          <div
            className={cn(
              { 'opacity-0': !Boolean(owner?.parameterValues[0]?.valueName) },
              'font-default-medium mb-2 truncate whitespace-nowrap text-xs text-grey-500',
            )}
          >
            {`Разработчик: ${owner?.parameterValues[0]?.valueName || ''}`}
          </div>

          <ProductAddButton product={data} className="mt-4" />
        </div>
      </div>
    </div>
  );
};
