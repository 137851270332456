import React, { FC, ReactNode } from 'react';
import { BasketProvider, CatalogProvider, GroupProvider, UserProvider } from 'src/shared/store';

interface ProvidersContainerProps {
  children?: ReactNode;
}

export const ProvidersContainer: FC<ProvidersContainerProps> = ({ children }) => {
  return (
    <UserProvider>
      <BasketProvider>
        <GroupProvider>
          <CatalogProvider>{children}</CatalogProvider>
        </GroupProvider>
      </BasketProvider>
    </UserProvider>
  );
};
