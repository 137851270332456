import { FC } from 'react';
import cn from 'classnames';

interface FilterTagListProps {
  title: string;
  activeFilters: string[];
  list: string[];
  setFilter: (title: string, filter: string) => void;
  classNames?: string;
}

export const FilterTagList: FC<FilterTagListProps> = ({
  title,
  activeFilters,
  list,
  setFilter,
  classNames,
}) => {
  return (
    <div className={cn('w-full', classNames)}>
      <div className="font-default-regular mb-5 text-sm">{title}</div>

      <div className="flex flex-wrap gap-2">
        {list.map(item => (
          <div
            className={cn(
              activeFilters.includes(item)
                ? 'bg-bgPressed text-primary-200 outline outline-1 outline-primary-200'
                : 'bg-bgPrimary',
              'font-default-regular cursor-pointer px-3 py-1.5 text-sm rounded-lg transition-all',
            )}
            key={`${title}-${item}`}
            onClick={() => setFilter(title, item)}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};
