import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowSmallIcon } from 'src/shared/assets/icons/kit/arrow-small.svg';
import okko from 'src/shared/assets/images/okko.png';
import cn from 'classnames';


interface OnlineEventsProps {
  className?: string;
}

const events: {
  id: number;
  name: string;
  img: string;
  link: string;
  description: string;
}[] = [
  {
    id: 1,
    name: "G2 Esports vs Natus Vincere G2 Esports vs Natus Vincere G2 Esports vs Natus Vincere",
    img: "https://static.okko.tv/images/v4/a14688e2-f5d8-404f-9f49-ea3c4b0dafba?presetId=3627&width=634&scale=2&quality=80&mediaType=webp",
    link: "https://okko.sport/sport/live_event/g2-esports-natus-vincere-562044889",
    description: "«G2 Esports» против «Natus Vincere» в рамках матча CS 2. Esports World Cup 2024«G2 Esports» против «Natus Vincere» в рамках матча CS 2. Esports World Cup 2024«G2 Esports» против «Natus Vincere» в рамках матча CS 2. Esports World Cup 2024",
  },
  {
    id: 2,
    name: "BetBoom Team vs PARIVISION",
    img: "https://static.okko.tv/images/v4/5a53e911-a356-4427-a1a3-5ccde361cf93?presetId=3627&width=360&scale=1&quality=80&mediaType=webp",
    link: "https://okko.sport/sport/live_event/betboom-team-parivision",
    description: "«BetBoom Team» против «PARIVISION» в рамках матча Dota 2. ESL One Bangkok 2024",
  },
  {
    id: 3,
    name: "Team Liquid vs Team Spirit",
    img: "https://static.okko.tv/images/v4/967f8a3f-9a5e-45b0-b149-93316adb346d?presetId=3627&width=360&scale=1&quality=80&mediaType=webp",
    link: "https://okko.sport/sport/live_event/team-liquid-team-spirit-594033959",
    description: "«Team Liquid» против «Team Spirit» в рамках матча Perfect World Shanghai Major 2024",
  },
  {
    id: 4,
    name: "Плей-офф. День 4, Dota 2. ESL One Bangkok 2024",
    img: "https://static.okko.tv/images/v4/5ee8ef46-6ddf-4d7b-b582-b2668b2eb343?presetId=3630&width=327&scale=1&quality=80&mediaType=webp",
    link: "https://okko.sport/sport/live_event/esl-one-bangkok-2024-play-off-day-6",
    description: "Плей-офф. День 4, Dota 2. ESL One Bangkok 2024",
  },
];

const OnlineEvents = ({ className }: OnlineEventsProps) => {

  const ref = useRef<HTMLDivElement>(null);
  // TODO: костыль, сделать нормальный скроллер. -1 значит, что ещё не были нажаты кнопки
  const [current, setCurrent] = useState<number>(-1);

  useEffect(() => {
    if (current === -1) return;
    if (!ref.current) return;
    const el = ref.current.querySelector(`[data-index="${current}"]`);
    if (!el) return;
    el.scrollIntoView({behavior: "smooth", inline: 'start', block: 'nearest'});
  }, [current]);

  return (
    <div className={className}>
      <div className="flex flex-row items-center justify-between">
        <div className="font-default-medium text-3xl">Онлайн события</div>
        <div className="flex flex-row gap-3">
          <button
            disabled={current < 1}
            onClick={() => setCurrent(prev => Math.max(prev - 1, 0))}
            className={cn(
              current > 0
                ? 'bg-primary-200 text-bgPrimary'
                : 'bg-bgPrimary text-primary-200',
              'aspect-square h-7 rounded-[50%]'
            )}
          >
            <ArrowSmallIcon className="rotate-0 h-3 stroke-[1.5]"/>
          </button>
          <button
            disabled={current === events.length - 1}
            onClick={() => setCurrent(prev => Math.min((prev === -1 ? 0 : prev) + 1, events.length - 1))}
            className={cn(
              current < events.length - 1
                ? 'bg-primary-200 text-bgPrimary'
                : 'bg-bgPrimary text-primary-200',
              'aspect-square h-7 rounded-[50%]',
            )}
          >
            <ArrowSmallIcon className="rotate-180 h-3 stroke-[1.5]"/>
          </button>
        </div>
      </div>
      <div ref={ref} className={"flex flex-row gap-[30px] overflow-hidden flex-nowrap"}>
        {events.map((ev, index) =>
          <div
            key={ev.id}
            data-index={index}
            className="flex w-[32rem] max-w-full flex-col gap-[30px]"
          >
            <a href={ev.link} target="_blank" className="aspect-video w-[32rem] max-w-full">
              <img src={ev.img} alt={ev.name} className="object-cover w-full" />
            </a>
            <div className="flex flex-col gap-4 h-[170px]">
              <div className="font-default-medium text-[22px] truncate overflow-ellipsis">{ev.name}</div>
              <div className="flex-1 font-default-regular opacity-60 overflow-hidden">{ev.description}</div>
              <a href={ev.link} target="_blank" className="h-8 flex flex-row gap-4">
                <img src={okko} alt="okko" className="rounded-[50%] w-8 h-8" />
                <div className="font-default-medium text-[22px] text-primary-200">
                  Смотреть в Okko
                </div>
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OnlineEvents;