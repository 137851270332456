import { FC, ReactNode } from 'react';
import cn from 'classnames';
import { Title } from 'src/shared/ui';

interface ContainerProps {
  classNames?: string;
  title?: string;
  head?: ReactNode;
  children?: ReactNode;
}

export const Container: FC<ContainerProps> = ({ classNames, title, head, children }) => {
  return (
    <div className={cn('mx-auto w-full max-w-[91.5rem] p-4', classNames)}>
      {title && <Title>{title}</Title>}
      {head}
      {children}
    </div>
  );
};
