import { ApiCatalog } from 'src/shared/api/ApiCatalog';
import { FiltersType } from 'src/shared/api/catalog/types';

const getFiltersStr = (sort: string, filters: Partial<FiltersType>) => {
  const result: string[] = [`sort=${sort}`];

  const { price, search, parameters, offset, selectedGroup } = filters;

  if (price?.min !== undefined) result.push(`priceMin=${price.min}`);
  if (price?.max !== undefined) result.push(`priceMax=${price.max}`);
  if (search !== undefined) result.push(`filter=${search}`);
  if (selectedGroup?.id !== undefined) result.push(`groupIds=${selectedGroup.id}`);
  if (offset !== undefined) result.push(`offset=${offset}`);

  const paramsArr: string[] = [];
  if (parameters !== undefined) {
    Object.keys(parameters).forEach(key => {
      if (parameters[key].length) {
        paramsArr.push(`${key}:${parameters[key].join('|')}`);
      }
    });
  }
  if (paramsArr.length > 0) result.push(`parameters=${paramsArr.join(',')}`);

  return result.join('&');
};

const getGroups = () => ApiCatalog.get(`/api/Catalog/Groups`);

const getFilters = (userId: string, groupIds: string, page: 'catalog' | 'favorite' | 'bought') =>
  ApiCatalog.get(`/api/Catalog/Filters?userId=${userId}&groupIds=${groupIds}&page=${page}`);

const getBanners = (id: number) => ApiCatalog.get(`/Page/${id}/ActiveBanners`);

const getProducts = (sort: string, filters: Partial<FiltersType>, userId: string) => {
  const str = getFiltersStr(sort, filters);

  return ApiCatalog.get(`/api/Catalog/Groups/Goods?userId=${userId}&${str}`);
};

const getTrendingProducts = () => {
  return ApiCatalog.get(`/api/Catalog/TrendingGoods?sort=rating|desc`);
};

const getProduct = (id: string, userId: string) =>
  ApiCatalog.get(`/api/Catalog/Groups/Good?goodId=${id}&userId=${userId}`);

const getSimilarProducts = (id: string, userId: string) =>
  ApiCatalog.get(`/api/Catalog/SimilarGoods?goodId=${id}&userId=${userId}`);

const setFavorite = (id: number) => ApiCatalog.post(`/api/Catalog/Favorite/Goods/${id}`);

const deleteFavorite = (id: number) => ApiCatalog.delete(`/api/Catalog/Favorite/Goods/${id}`);

const getFavoriteProducts = (
  sort: string,
  filters: FiltersType,
  userId: string,
  isBought?: boolean,
) => {
  const str = getFiltersStr(sort, filters);
  const boughtStr = isBought ? `&bought=${true}` : '';

  return ApiCatalog.get(`/api/Catalog/Favorite/Goods?userId=${userId}&${str}${boughtStr}`);
};

const CatalogService = {
  getGroups,
  getProducts,
  getTrendingProducts,
  getProduct,
  getSimilarProducts,
  getFilters,
  getBanners,
  getFavoriteProducts,
  setFavorite,
  deleteFavorite,
};

export default CatalogService;
