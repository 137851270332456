import { FC, useState } from 'react';
import cn from 'classnames';
import { Container } from 'src/entity/Container';
import { BannersList } from 'src/feature/BannersList';
import { GridFilter } from 'src/feature/GridFilter';
import { ProductGridCard } from 'src/feature/ProductGridCard';
import useBanners from 'src/hooks/useBanners';
import { ProductType } from 'src/shared/api/catalog';
import { sort } from 'src/shared/const';
import { Loader } from 'src/shared/ui/Loader';
import { CatalogHead } from 'src/widgets/CatalogHead';

import { useFilters, useProducts } from './hooks';

interface CatalogPageProps {
  isFavorite?: boolean;
}

export const CatalogPage: FC<CatalogPageProps> = ({ isFavorite }) => {
  // const { width } = useWindowDimensions();
  const { banners } = useBanners(4);

  // const [isGrid, setIsGrid] = useState(true);
  const [selectedSort, setSelectedSort] = useState(sort[0]);
  const [isBought, setIsBought] = useState<boolean>(window.location.search.includes('bought'));

  useFilters(isFavorite, isBought);
  const { isLoading, products, setProducts } = useProducts(selectedSort, isBought, isFavorite);

  const onFavorite = (product: ProductType) => {
    setProducts(prev =>
      prev.map(el => (el.id === product.id ? { ...el, favorite: !el.favorite } : el)),
    );
  };

  return (
    <Container classNames="">
      {isFavorite ? (
        <div className="font-default-medium mb-4 flex">
          <button
            onClick={() => setIsBought(false)}
            className={cn(
              isBought
                ? 'hover:border-primary-200 hover:text-textLighter'
                : 'border-primary-100',
              'border-b border-bgPrimary px-2.5 pb-2 transition-all',
            )}
          >
            Сохранили
          </button>
          <button
            onClick={() => setIsBought(true)}
            className={cn(
              isBought
                ? 'border-primary-100'
                : 'hover:border-primary-200 hover:text-textLighter',
              'border-b border-bgPrimary px-2.5 pb-2 transition-all',
            )}
          >
            Покупали
          </button>
          <div className="w-full border-b border-bgPrimary" />
        </div>
      ) : null}

      <div className="flex flex-1">
        <div className="hidden h-full w-72 flex-shrink-0 d-xs:block">
          <div className="sticky top-20">
            <div className="flex flex-col">
              <GridFilter />
            </div>
          </div>
        </div>

        <div className="flex flex-1 flex-col d-xs:ml-5">
          {banners?.length && !isFavorite ? (
            <div className="aspect-banner-catalog mb-4 w-full overflow-hidden">
              <BannersList list={banners} />
            </div>
          ) : null}

          <CatalogHead
            // временно убрали переключение сетки на список
            // isGrid={isGrid}
            // setIsGrid={setIsGrid}
            selectedSort={selectedSort}
            setSelectedSort={setSelectedSort}
          />

          <div className="sticky flex-1">
            <div className={/* cn({ */ 'flex flex-wrap d-xs:gap-2.5' /*: isGrid })*/}>
              {isLoading && <Loader classNames="mx-auto mt-40 w-20 h-20 text-primary-100" />}

              {!isLoading && !products.length ? (
                <div className="font-default-medium mx-auto mt-40">Товары не найдены</div>
              ) : null}

              {!isLoading && products.length
                ? products.map(
                    el => <ProductGridCard onFavorite={onFavorite} key={el.id} data={el} />,
                    // временно убрали переключение сетки на список
                    // !isGrid && width >= 1200 ? (
                    //   <ProductListCard onFavorite={onFavorite} key={el.id} data={el} />
                    // ) : (
                    //   <ProductGridCard onFavorite={onFavorite} key={el.id} data={el} />
                    // ),
                  )
                : null}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
